import React, {useState, useEffect} from 'react'
import Header  from './../Components/Header';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import Loader from './../Components/Loader';
import { Link, useHistory } from "react-router-dom"; 



function MenuCategory(){
	const {id} = useParams()
	const url = `https://digitalmenu.vulpo.be/api/1/category/${id}`;
	const [category, setCategory] = useState({
		loading: false,
		data: null,
		error: false
	});

    let history = useHistory();
	let content = null;

	useEffect(() => {
		setCategory({
			loading: true,
			data: null,
			error: false
		})
		axios.get(url)
			.then(response => {
				setCategory({
					loading: false,
					data: response.data,
					error: false
				});
			})
			.catch(error => {

				setCategory({
					loading: false,
					data: null,
					error: true
				});
			})
	}, [url])

	if(category.error){
		content = 
			<section className="error-page">
			  	<div className="container">
			  		<div className="row">
			  			<div className="col-12 col-md-6 offset-md-3">
					  		<div className="error-content">
					  			<h1>Oooops !</h1>
					  			<h3>Page not found</h3>
								<Link to={'/'} className="btn-accent" >Go Home</Link>
					  		</div>
					  	</div>
				  	</div>
		  		</div>
		  	</section>

	}else{
		if(category.loading){
			content = <Loader/>
		}else{
			if(category.data){
				
				if(category.data.productgroups.length == 0){
					content = 
						<section className="category-menu">
					  		<div className="container">
					  			<div className="row">
					  				<div className="col-12 col-md-6 offset-md-3">
					  					{category.data.subcategories.sort((a, b) => (a.order > b.order) ? 1 : -1).map((cat,key) => 
											<Link to={`/category/${cat.id}`} className="m-btn" >{cat.category_name.nl}</Link>
						  				)}
					  				</div>
					  			</div>
					  		</div>
					  	</section>

				}else{
					content = 
						<section className="category-detail">
					  		<div className="container">
					  			{category.data.productgroups.sort((a, b) => (a.order > b.order) ? 1 : -1).map((group, key) => 
						  			<div className="row">
						  				<div className="col-12 col-md-6 offset-md-3">
											<div className="c-table">
							  					<h3>{group.group_name.nl}</h3>

					  							{group.products.sort((a, b) => (a.order > b.order) ? 1 : -1).map((prod,key) => 
								  					<div>
									  					<div className="row">
									  						<div className="col-8">
									  							<p>{prod.product_name.nl}</p>
									  						</div>
									  						<div className="col-4">
								  								<div className="price">
								  									<span>€ {prod.product_price}</span>
								  								</div>
																  							
									  						</div>
									  					</div>
									  					<p className="note">{prod.product_descr.nl}</p>
								  					</div>
				  								)}
							  					
							  					
							  				</div>
							  			</div>
							  		</div>
				  				)}

					  			

					  		</div>
					  	</section>

					
				}
				
			}
		}
	}

	



	return(
		<div>
			
			<Header/>
		  	<main id="main">
			  	{content}
			</main>
		  
			<footer>
				<div className="container">
					<div className="row">
						<div className="col-6">
							<p>	+32 9 277 90 50 </p>
						</div>
						<div className="col-6">
							<p>	info@otus.be</p>
						</div>
					</div>
				</div>
			</footer>
		</div>
	


	)
}

export default MenuCategory 