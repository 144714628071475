import React, {useState, useEffect} from 'react'
import Header  from './../Components/Header';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import Loader from './../Components/Loader';
import { Link } from "react-router-dom"; 


import drankicon from './../img/drank_icon.svg'
import menuicon from './../img/menu_icon.svg'
import koffieicon from './../img/koffie_icon.svg'
import wijnicon from './../img/wijn_icon.svg'


function Menu(){
	const url = 'https://digitalmenu.vulpo.be/api/1/categories';
	const [category, setCategory] = useState({
		loading: false,
		data: null,
		error: false
	});

	let content = null;

	useEffect(() => {
		setCategory({
			loading: true,
			data: null,
			error: false
		})
		axios.get(url)
			.then(response => {
				setCategory({
					loading: false,
					data: response.data,
					error: false
				});
			})
			.catch(error => {

				setCategory({
					loading: false,
					data: null,
					error: true
				});
			})
	}, [url])

	if(category.error){
		content = 
			<section classname="error-page">
			  	<div classname="container">
			  		<div classname="row">
			  			<div classname="col-12 col-md-6 offset-md-3">
					  		<div classname="error-content">
					  			<h1>Oooops !</h1>
					  			<h3>Page not found</h3>
								<Link to={'/'} className="btn-accent" >Go Home</Link>
					  		</div>
					  	</div>
				  	</div>
		  		</div>
		  	</section>
	}else{
		if(category.loading){
			content = <Loader/>
		}else{
			if(category.data){
				// load standard with foreach
				content = 
					<section className="category">
				  		<div className="container">
	  						<div className="row">
	  							<div className="offset-md-3 col-md-6 offset-0 col-12">
						  			<div className="row">
						  			
										{category.data.map((cat,key) => 
											<div className="col-6">
							  					<Link to={`/category/${cat.id}`} className="c-btn" ><img src={`https://digitalmenu.otus.be/hardassets/${cat.category_icon}`} />{cat.category_name.nl}</Link>
							  				</div>
						  				)}
						  			</div>
					  			</div>
				  			</div>
				  		</div>
				  	</section>
				
			}
		}
	}

	



	return(
		<div>
			
			<Header/>

		  	<main id="main">
			  	{content}
			</main>
		  
			<footer>
				<div className="container">
					<div className="row">
						<div className="col-6">
							<p>	+32 9 277 90 50 </p>
						</div>
						<div className="col-6">
							<p>	info@otus.be</p>
						</div>
					</div>
				</div>
			</footer>
		</div>
	


	)
}

export default Menu 