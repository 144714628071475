import React, {useState} from 'react'
import logo from './../img/logo_otus.png'
import { Link } from "react-router-dom"; 

function Home(){
	return(

		<div>
			<header id="header">
				<div className="homepage-header">
		 			<img src={logo}/>
		 		</div>
	 		</header>
	 		<main id="main">
		  		<section className="homepage">
				  	<div className="container">
				  		<div className="row">
				  			<div className="col-12 col-md-6 offset-md-3">
						  		<div className="home-content">
						  			<h1>Hartelijk welkom !</h1>
						  			<ul>
						  				<li><Link className="btn-accent" to="/categories" >Menu</Link></li>
						  				<li><Link className="btn" to="/about" >Info</Link></li>
										{/*<li>
											<button className="btn-accent" href="#">NL
												<ul>
													<li><a href="#">FR</a></li>
													<li><a href="#">ENG</a></li>
													<li><a href="#">DU</a></li>
												</ul>
											</button>
										</li>*/}
						  			</ul>
						  		</div>
						  	</div>
					  	</div>
			  		</div>
			  	</section>
			</main>
		</div>
	)
}

export default Home 