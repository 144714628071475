import React from 'react';
import {
	BrowserRouter as Router,
	Switch,
	Route,
	Link
} from "react-router-dom"; 
import './css/style.min.css';
import Header  from './Components/Header';
import Home from './Views/Home';
import About from './Views/About';
import Menu from './Views/Menu';
import MenuCategory from './Views/Category';

function App() {
  return (
    	<Router>
	    	
	    	<Switch>
	    		<Route exact path="/">
	    			<Home />
	    		</Route>
	    		<Route path="/about">
	    			<About />
	    		</Route>
	    		<Route path="/categories">
	    			<Menu />
	    		</Route>
	    		<Route path="/category/:id">
	    			<MenuCategory />
	    		</Route>
	    	</Switch>

    	</Router>
  );
}

export default App;
