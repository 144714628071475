import React, {useState} from 'react'
import { Link } from "react-router-dom"; 

function Nav(){
	const [showMenu, setShowMenu] = useState(false)
	let menu

	if(showMenu){
		menu = 
			<section id="overlayNav" className="overlay-nav">
		  		<div className="container">
			  		<button id="closeNav" onClick={() => setShowMenu(false)}><i className="lni-close"></i></button>
			  		<div className="row">
			  			<div className="col-12 col-md-6 offset-md-3">
					  		<div className="overlay-content">
					  			<h1>Restaurant Otus</h1>
					  			<ul>
									<li><Link to="/categories" className="btn" onClick={() => setShowMenu(false)}>Menu</Link></li>
									<li><Link to="/about" className="btn" onClick={() => setShowMenu(false)}>Info</Link></li>
									{/*<li>
										<button className="btn-accent" href="#">NL
											<ul>
												<li><a href="#">FR</a></li>
												<li><a href="#">ENG</a></li>
												<li><a href="#">DU</a></li>
											</ul>
										</button>
									</li>*/}
					  			</ul>
					  		</div>
					  	</div>
				  	</div>
		  		</div>
		  	</section>


		  	
	}

	return(
		<nav>
  			<button className="mobile" onClick={() => setShowMenu(!showMenu) } id="openNav"></button>
			{menu}
		</nav>
	)
}

export default Nav 