import React, {useState} from 'react'
import Header  from './../Components/Header';

function About(){
	return(
		<div>
			
			<Header/>

		  	<main id="main">
			  	<section className="category-question">
			  		<div className="container">
			  			<div className="q-table">
				  			<div className="row">
				  				<div className="col-12">
				  					<h3>Spelregels</h3>
				  					<p>Maximum 4 hoofdgerechten per tafel</p>
			  						<p>Slechts één rekening per tafel</p>
			  						<p>De keuken is gesloten op weekdagen tussen 15 en 18 uur.</p>
				  					
				  				</div>
				  			</div>
				  		</div>

			  		</div>
			  	</section>
			</main>
		  
			<footer>
				<div className="container">
					<div className="row">
						<div className="col-6">
							<p>	+32 9 277 90 50 </p>
						</div>
						<div className="col-6">
							<p>	info@otus.be</p>
						</div>
					</div>
				</div>
			</footer>
		</div>
	)
}

export default About 