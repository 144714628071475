import React from 'react'

function Loader(){
	return(
		<div className="loader_page">
			<div className="loader"></div>
 		</div>
	)
}

export default Loader