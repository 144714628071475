import React from 'react'
import Nav  from './Nav';
import logo from './../img/logo_otus.png'
import logoneg from './../img/logo_otus_neg.png'
import backbtn from './../img/arrow_icon.svg'
import { Link, useHistory } from "react-router-dom"; 

function Header(){

    let history = useHistory();

	return(
		<header id="header">
	 		<div className="mynavbar">
	 			<div className="container">
	 				<div className="row">
	 					<div class="col-4 align-self-center" >
	 						<button style={{backgroundColor: 'transparent'}} href="#" onClick={() => history.goBack()}><img className="arrow" src={backbtn} alt="backbutton" /></button>
	 					</div>
	 					<div className="col-4 ">
	 						<Link to="/" className="btn-logo" ><img id="logo" src={logoneg} alt="logo" /></Link>
	 						
	 					</div>
	 					<div className="col-4 ">
			      			<Nav/>
			      			{/*<button className="mobile" id="openNav"><i className="lni-menu"></i></button>*/}
			      		</div>

			      		
			      	</div>
			    </div>
			</div>

			{/*<div className="bg-header">
				<div className="container">
					<div className="row">
			      		<div className="col-12">
			      			<h2>Lorem ipsum dolor sit amet</h2>
			      			<form>
			      				<div className="form-group">
			      					<div className="col-12">
			      						<input type="text" className="search" placeholder="Zoek..." />
			      					</div>
			      				</div>
			      			</form>
			      		</div>
			      	</div>
			    </div>
			</div>*/}
			
	 	</header>
	)
}

export default Header